<template>
	<div class="grid">
		<div class="col-9">
			<div class="card">
                <h3>Mensaje</h3>
                <Editor v-model="sHtml" editorStyle="height: 320px"/>
                <Button label="Enviar" @click="sendMail" class="p-button-rounded mr-2 mb-2"></Button>
            </div>
        </div>
        <div class="col-3">
              
            <div class="card">
                <div class="formgrid grid">
                    <div class="field col">
                        <label for="price">Asunto</label>
                        <InputText id="name" type="text" v-model="sSubject"/>
                    </div>
                </div>
                <div class="formgrid grid">
                    <div class="field col">
                        <label for="price">Lista de Clientes</label>
                        <Dropdown v-model="idSegment" :options="segments" optionValue="idOption" optionLabel="sOption" placeholder="Elige un Segmento" ></Dropdown>
                    </div>
                    <div v-if="idSegment==3" class="field col">
                        <Textarea v-model="sMailList" cols="25" rows="10"  />
                    </div>
                </div>
            </div>            
        </div>
	</div>

</template>
<script>
/* eslint-disable */
export default {
	data() {
		return {
            segments: null, 
            sHtml: '<img src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/vitialife.jpg" />',
            bCorrect: false,
            idSegment: 0, 
            sMailList: '',
            sSubject: ''
		}
	},
	created() {
	},
	mounted() {
		this.isTokenExpired()
        this.getSegments()
        
	},
	methods: {
		async sendMail(){
            if(this.idSegment == 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Debe elegir un segemento',
                    footer: ''
                })
                return
            }

            if(this.sSubject == ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'El asunto es requerido',
                    footer: ''
                })
                return
            }

            if(this.idSegment == 3 && this.sMailList == ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Debe especificar al menos un correo',
                    footer: ''
                })
                return
            }

            if(this.sHtml == ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'El correo no puede ir vacío',
                    footer: ''
                })
                return
            }

            let model = {
                idSegmentType: this.idSegment,
                sSubject: this.sSubject,
                sMessage: this.sHtml,
                sMailList: this.sMailList
            }

            this.openLoading('Enviando Correos...')
            await axios.post(apiURL + 'Syncronize/sendCustomerMail', model, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
            .then( (response) => {
                Swal.close()
                if(response.data.bCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })						
                    return
                }
                else{
                    Swal.fire('Los correos fueron enviados')
                }
            } )
            .catch( (error)  => {
                Swal.close()
                console.log("error")
            } )
            
        },
        async getSegments(){
            await axios.get(apiURL + 'Customers/getSegments', { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
            .then( (response) => {
                this.segments = response.data.options
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },

	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
